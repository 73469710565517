<template>
  <div class="waiting-for-approval">
    <v-container class="pa-0 mb-0 mb-md-8">
      <div class="card">
        <v-card
          class="transparent px-4 py-3 px-md-6 pt-10 pb-6"
          elevation="0"
          flat
          tile
        >
          <v-row>
            <!-- Left -->
            <v-col cols="12" sm="7" md="8">
              <!-- Waiting for approval -->
              <h1 class="hearder-h3">Waiting for approval</h1>
              <p
                v-if="
                  auth &&
                  auth.user &&
                  auth.user.open_application &&
                  auth.user.open_application.has_truid
                "
                class="b-body-1 mt-1"
              >
                Your application is being processed. You will receive an SMS
                with feedback as soon as the process is finished. Thank you for
                choosing Lime24!
              </p>

              <p v-else class="b-body-1 mt-1">
                Your application will be processed after analyzing the bank
                statement by the manager. You will receive an SMS with feedback
                as soon as the process is finished. Thank you for choosing
                Lime24!
              </p>
              <v-row>
                <!-- <v-col cols="12" sm="6" md="6">
                  <v-card max-width="300" flat>
                    
                    <FilesUpload />
                  </v-card>
                </v-col> -->
                <!-- <v-col cols="12" sm="6" md="6">
                  <v-card max-width="300" flat>

                    <PayslipUpload />
                  </v-card>

                </v-col> -->
              </v-row>
            </v-col>

            <!-- Right -->
            <v-col cols="12" sm="5" md="4">
              <v-img
                alt="Completed"
                class="completed"
                contain
                src="@/assets/img/dashboard/pages/my-loan/waiting-for-approval.svg"
                max-width="286"
              />
            </v-col>
          </v-row>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

import FilesUpload from "@/components/dashboard/pages/waiting-for-approval/FileUpload";
import PayslipUpload from "@/components/dashboard/pages/waiting-for-approval/PayslipUpload";

export default {
  name: "LimeLoansWaitingForApproval",

  components: { FilesUpload, PayslipUpload },

  data() {
    return {};
  },

  computed: {
    ...mapState({ auth: "auth" }),
  },

  created() {
    // Get User Details After 7 Minutes
    setTimeout(() => {
      // getUserDetails
      this.getUserDetails();
    }, 420000);
  },

  mounted() {},

  methods: {
    ...mapActions({
      getApiUserDetails: "auth/getApiUserDetails",
    }),

    // getUserDetails
    async getUserDetails() {
      await this.getApiUserDetails();
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
